import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from '../scrollWithOffset';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { Break, BtnStyle } from '../style';
import BgImg from '../../img/home/home_achievement_bg.jpg';
import BgImg_SP from '../../img/home/home_achievement_bg-SP.jpg';

const Outer = styled.section`
	width: 100%;
	max-width: 1440px;
	padding: 50px 10px;
	margin: 0 auto 100px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	background-image: url(${BgImg_SP});
	text-align: center;
	@media ${v.media_md} {
		padding: 150px 0;
		margin-bottom: 200px;
		background-image: url(${BgImg});
	}
`;
const Lead = styled.p`
	&:not(:last-child) {
		margin-bottom: 10px;
	}
	@media ${v.media_md} {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
`;
const Btn = styled(HashLink)`
	${BtnStyle}
	margin: 30px auto 0;
	background-color: ${v.color_txt};
	color: ${v.color_base};
	border-color: ${v.color_txt};
	@media ${v.media_md} {
		margin-top: 50px;
	}
	@media all and (any-hover: hover) {
		&:hover {
			color: ${v.color_txt};
		}
	}
`;
const HomeAchievement = () => {
	return (
		<Outer id="achievement">
			<Lead>
				カスタムカーを長年<Break>作り続けてきた実績から</Break>
				<br />
				お客様により愛車が好きになるカスタムを
				<Break>ご提案ができる自信があります。</Break>
			</Lead>
			<Lead>
				過去のカスタム例は<Break>こちらからご覧ください。</Break>
			</Lead>
			<Btn smooth to="/gallery#" scroll={(el) => scrollWithOffset(el)}>
				ギャラリーページへ
			</Btn>
		</Outer>
	);
};
export default HomeAchievement;
