import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { IconFb, IconInsta, IconPinte } from '../icon';
import { scrollWithOffset } from '../scrollWithOffset';
import styled from 'styled-components';
import stylevs from '../../style-variables.json';
const v = stylevs.var;
import LogoImg from '../../img/logo.svg';

const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	padding: 10px 15px;
	text-transform: uppercase;
	line-height: 1;
	@media ${v.media_xl} {
		align-items: center;
	}
`;
const GnavBtn = styled.button`
	position: relative;
	display: inline-block;
	appearance: none;
	border: none;
	cursor: pointer;
	z-index: 9999;
	width: 45px;
	height: 45px;
	border-radius: 5px;
	outline: none;
	background-color: ${v.color_sub};
	background-color: transparent;
	&:hover,
	&:focus {
		span::before {
			transform: rotate(10deg);
		}
		span::after {
			transform: rotate(-10deg);
		}
	}
	/* メニューOPEN時の<Line>の動き */
	&[aria-expanded='true'] span {
		background-color: transparent;
	}
	&[aria-expanded='true'] span::before,
	&[aria-expanded='true'] span::after {
		top: 0;
		background-color: ${v.color_txt};
	}
	&[aria-expanded='true'] span::before {
		transform: rotate(45deg);
	}
	&[aria-expanded='true'] span::after {
		transform: rotate(-45deg);
	}
	@media ${v.media_xl} {
		display: none;
	}
`;
// ハンバーガーボタンの線
const Line = styled.span`
	position: absolute;
	/* 高さの相殺 */
	top: calc(50% - 2px);
	left: 50%;
	transform: translate(-50%);
	width: 22px;
	height: 2px;
	border-radius: 1px;
	background-color: ${v.color_txt};
	transition: all 0.3s;
	::before,
	::after {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: inherit;
		background-color: inherit;
		transition: inherit;
	}
	::before {
		top: -8px;
	}
	::after {
		top: 8px;
	}
`;
const Nav = styled.nav`
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	width: 65%;
	height: 100vh;
	padding: 50px 10px;
	margin: 0 auto;
	background-color: ${v.color_main};
	@media ${v.media_sm} {
		width: 45%;
	}
	@media ${v.media_md} {
		width: 30%;
	}
	@media ${v.media_xl} {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: 1366px;
		height: auto;
		padding: 10px 30px;
		background-color: transparent;
	}
	&[aria-expanded='true'] {
		display: flex;
	}
`;
const List = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 30px;
	@media ${v.media_xl} {
		flex-direction: row;
		margin-bottom: 0;
	}
	li {
		&:not(:last-child) {
			margin-bottom: 20px;
			@media ${v.media_xl} {
				margin-bottom: 0;
				margin-right: 20px;
			}
		}
	}
`;
const SNSList = styled.ul`
	display: flex;
	align-items: center;
	li {
		&:not(:last-child) {
			margin-right: 30px;
		}
	}
`;
// Navリンクの共通スタイル
const StyledLink = styled(HashLink)`
	display: inline-block;
	position: relative;
	color: ${v.color_txt};
	text-align: center;
	text-decoration: none;
	@media all and (any-hover: hover) {
		&:hover {
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: -10px;
				width: 80%;
				height: 2px;
				background-color: ${v.color_main};
			}
		}
	}
`;
const Logo = styled.h1`
	margin-bottom: 30px;
	@media ${v.media_xl} {
		margin-bottom: 0;
	}
	img {
		width: 120px;
		height: auto;
		@media ${v.media_lg} {
			width: 150px;
		}
		@media ${v.media_xl} {
			width: 120px;
		}
	}
`;
const SPLogoLink = styled(HashLink)`
	img {
		width: 120px;
		height: auto;
	}
	@media ${v.media_xl} {
		display: none;
	}
`;

// 「メニューを開閉する」テキストの非表示、スクリーンリーダー読み込ませるため(display: noneは読み込まれない)
const VisuallyHidden = styled.span`
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
`;

const Gnav = () => {
	const [isOpen, setOpen] = useState(false);
	const toggle = () => {
		setOpen(!isOpen);
	};
	// navmenuが開いている時にメニューリンクのどれかをクリックした時メニューを閉じる
	const closeNav = () => {
		if (isOpen === true) {
			setOpen(false);
		}
	};

	return (
		<Header>
			<SPLogoLink
				smooth
				to="/#"
				onClick={closeNav}
				scroll={(el) => scrollWithOffset(el)}
			>
				<img
					src={LogoImg}
					alt="Original Work Shop Fate"
					width="205"
					height="88"
				/>
			</SPLogoLink>
			<GnavBtn
				type="button"
				id="GnavBtn"
				aria-controls="Gnav"
				aria-expanded={isOpen}
				onClick={toggle}
			>
				<Line>
					<VisuallyHidden>メニューを開閉する</VisuallyHidden>
				</Line>
			</GnavBtn>
			<Nav aria-controls="Gnav" aria-expanded={isOpen}>
				<Logo>
					<HashLink
						smooth
						to="/#"
						onClick={closeNav}
						scroll={(el) => scrollWithOffset(el)}
					>
						<img
							src={LogoImg}
							alt="Original Work Shop Fate"
							width="205"
							height="88"
						/>
					</HashLink>
				</Logo>
				<List>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/#about"
							scroll={(el) => scrollWithOffset(el)}
						>
							こだわり
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/gallery#"
							scroll={(el) => scrollWithOffset(el)}
						>
							ギャラリー
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/#roadservice"
							scroll={(el) => scrollWithOffset(el)}
						>
							ロードサービス
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/#news"
							scroll={(el) => scrollWithOffset(el)}
						>
							ニュース
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="#contact"
							scroll={(el) => scrollWithOffset(el)}
						>
							お問い合わせ
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/access#"
							scroll={(el) => scrollWithOffset(el)}
						>
							アクセス
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/insurance#"
							scroll={(el) => scrollWithOffset(el)}
						>
							保険事業部
						</StyledLink>
					</li>
					<li>
						<StyledLink
							as="a"
							onClick={closeNav}
							target="_blank"
							rel="noreferrer"
							href="https://fate-cars.stores.jp/"
						>
							オンラインストア
						</StyledLink>
					</li>
				</List>
				<SNSList>
					<li>
						<StyledLink
							as="a"
							onClick={closeNav}
							target="_blank"
							rel="noreferrer"
							href={v.url_fb}
						>
							<IconFb />
						</StyledLink>
					</li>
					<li>
						<StyledLink
							as="a"
							onClick={closeNav}
							target="_blank"
							rel="noreferrer"
							href={v.url_insta}
						>
							<IconInsta />
						</StyledLink>
					</li>
					<li>
						<StyledLink
							as="a"
							onClick={closeNav}
							target="_blank"
							rel="noreferrer"
							href={v.url_pinte}
						>
							<IconPinte />
						</StyledLink>
					</li>
				</SNSList>
			</Nav>
		</Header>
	);
};
export default Gnav;
