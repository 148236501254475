import React from 'react';
import Svg from './svg';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;

// gnavアイコンの共通設定
const Icon = styled(Svg)`
	padding: 1px;
	@media ${v.media_md} {
	}
`;

export const IconFb = () => (
	<Icon width="12.5" height="21.5" viewBox="0 0 12.5 21.5" role="img" aria-label="Facebook">
		<path
			fill="currentcolor"
			d="M15,1.25h3a.75.75,0,0,1,.75.75V6a.75.75,0,0,1-.75.75H15a.25.25,0,0,0-.25.25V9.25H18a.75.75,0,0,1,.728.932l-1,4A.75.75,0,0,1,17,14.75H14.75V22a.75.75,0,0,1-.75.75H10A.75.75,0,0,1,9.25,22V14.75H7A.75.75,0,0,1,6.25,14V10A.75.75,0,0,1,7,9.25H9.25V7A5.757,5.757,0,0,1,15,1.25Zm2.25,1.5H15A4.255,4.255,0,0,0,10.75,7v3a.75.75,0,0,1-.75.75H7.75v2.5H10a.75.75,0,0,1,.75.75v7.25h2.5V14a.75.75,0,0,1,.75-.75h2.414l.625-2.5H14a.75.75,0,0,1-.75-.75V7A1.752,1.752,0,0,1,15,5.25h2.25Z"
			transform="translate(-6.25 -1.25)"
		/>
	</Icon>
);

export const IconInsta = () => (
	<Icon width="21.5" height="21.5" viewBox="0 0 21.5 21.5"role="img" aria-label="Instagram">
		<g transform="translate(0.75 0.75)">
			<path
				fill="currentcolor"
				d="M5-.75H15A5.757,5.757,0,0,1,20.75,5V15A5.757,5.757,0,0,1,15,20.75H5A5.757,5.757,0,0,1-.75,15V5A5.757,5.757,0,0,1,5-.75Zm10,20A4.255,4.255,0,0,0,19.25,15V5A4.255,4.255,0,0,0,15,.75H5A4.255,4.255,0,0,0,.75,5V15A4.255,4.255,0,0,0,5,19.25Z"
			/>
			<path
				fill="currentcolor"
				d="M12.042,7.207a4.776,4.776,0,0,1,.7.052A4.75,4.75,0,0,1,14.179,16.2a4.771,4.771,0,0,1-2.134.507,4.75,4.75,0,0,1,0-9.5Zm0,8a3.25,3.25,0,1,0-2.3-.952A3.23,3.23,0,0,0,12.044,15.207Z"
				transform="translate(-1.559 -2)"
			/>
			<path
				fill="currentcolor"
				d="M.01.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H.01A.75.75,0,0,1,.76,0,.75.75,0,0,1,.01.75Z"
				transform="translate(15.941 4.5)"
			/>
		</g>
	</Icon>
);

export const IconPinte = () => (
	<Icon width="15.559" height="20" viewBox="0 0 15.559 20" role="img" aria-label="Pinterest">
		<path
			fill="currentcolor"
			d="M8.617,13.227C8.091,15.981,7.45,18.621,5.549,20c-.586-4.162.861-7.287,1.534-10.6-1.147-1.93.138-5.812,2.555-4.855,2.975,1.176-2.576,7.172,1.15,7.922,3.891.781,5.479-6.75,3.066-9.2C10.369-.275,3.708,3.18,4.528,8.245c.2,1.238,1.478,1.613.511,3.322-2.231-.494-2.9-2.254-2.811-4.6A7.506,7.506,0,0,1,9,.067C13.2-.4,17.143,1.61,17.688,5.561c.613,4.461-1.9,9.293-6.389,8.945-1.218-.1-1.728-.7-2.682-1.279Z"
			transform="translate(-2.221 0)"
		/>
	</Icon>
);

export const IconPhone = () => (
	<Icon width="21.389" height="21.428" viewBox="0 0 21.389 21.428" role="img" aria-label="電話アイコン">
		<path
			fill="currentcolor"
			d="M20,22.678c-.082,0-.166,0-.248-.011h-.014a20.639,20.639,0,0,1-8.955-3.185,20.383,20.383,0,0,1-6.225-6.225,20.639,20.639,0,0,1-3.185-9V4.248a2.75,2.75,0,0,1,2.736-3H7.13A2.763,2.763,0,0,1,9.853,3.615v.007a12.108,12.108,0,0,0,.659,2.646,2.757,2.757,0,0,1-.619,2.9l-.861.861a15.257,15.257,0,0,0,4.939,4.939l.858-.858a2.75,2.75,0,0,1,2.9-.622,12.1,12.1,0,0,0,2.644.658h.007a2.75,2.75,0,0,1,2.365,2.781V19.92A2.75,2.75,0,0,1,20,22.678Zm-.107-1.5c.035,0,.071,0,.107,0a1.25,1.25,0,0,0,1.25-1.255v-3q0-.009,0-.019a1.25,1.25,0,0,0-1.073-1.268,13.61,13.61,0,0,1-2.97-.74,1.25,1.25,0,0,0-1.32.281L14.62,16.44a.75.75,0,0,1-.9.122,16.758,16.758,0,0,1-6.281-6.281.75.75,0,0,1,.122-.9L8.83,8.11a1.252,1.252,0,0,0,.278-1.316,13.614,13.614,0,0,1-.741-2.972A1.256,1.256,0,0,0,7.13,2.75H4.11A1.25,1.25,0,0,0,2.866,4.107a19.131,19.131,0,0,0,2.953,8.335l0,.006a18.873,18.873,0,0,0,5.769,5.769l.006,0A19.133,19.133,0,0,0,19.893,21.174Z"
			transform="translate(-1.362 -1.25)"
		/>
	</Icon>
);
