import React from 'react';
import styled from 'styled-components';
import { UnderOuter } from '../components/style';
import smoothscroll from 'smoothscroll-polyfill';
import GalleryAbout from '../components/gallery/gallery-about';
import GalleryPhoto from '../components/gallery/gallery-photo';

const Outer = styled.main`
	${UnderOuter}
`;
const Gallery = () => {
	smoothscroll.polyfill();
	return (
		<Outer>
			<GalleryAbout />
			<GalleryPhoto />
		</Outer>
	);
};
export default Gallery;
