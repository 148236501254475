import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import {
	OuterStyle,
	Break,
	TxtFont,
	AttentionFont,
	OuterPadding,
} from '../style';
import RoadserviceImg1 from '../../img/home/home_roadservice_01.jpg';
import RoadserviceImg1_2x from '../../img/home/home_roadservice_01@2x.jpg';

const Outer = styled.div`
	${OuterStyle}
	${OuterPadding}
	@media ${v.media_lg} {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;
const Img = styled.img`
	width: 100%;
	height: auto;
	@media ${v.media_sm} {
		display: none;
	}
	@media ${v.media_lg} {
		display: inline-block;
		order: 1;
		width: 40%;
		max-width: 438px;
	}
`;
const Wrapper = styled.div`
	width: 100%;
	max-width: 750px;
	margin: 0 auto;
	@media ${v.media_lg} {
		order: 2;
	}
`;
const Lead = styled.p`
	margin-bottom: 20px;
	text-align: center;
	@media ${v.media_md} {
		margin-bottom: 30px;
	}
`;
const Container = styled.div`
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	width: 100%;
	@media ${v.media_sm} {
		/* cardのmargin差分吸収 */
		margin-bottom: -20px;
	}
`;
const Card = styled.div`
	width: 47%;
	padding: 20px 10px;
	margin: 0 auto 20px;
	background-color: ${v.color_main};
	box-shadow: 0px 7px 29px ${v.color_main};
	border-radius: 15px;
	${AttentionFont}
	letter-spacing: 0;
	@media ${v.media_sm} {
		width: 31%;
	}
`;
const CardTitle = styled.h2`
	margin-bottom: 5px;
	${TxtFont}
	text-align: center;
	@media ${v.media_md} {
		margin-bottom: 10px;
	}
`;

const HomeRoadService = () => {
	return (
		<Outer id="roadservice">
			<Wrapper>
				<Lead>
					Fateではアフターケアにも<Break>力を入れております。</Break>
					<br />
					一大事にも安心の24時間対応<Break>緊急ロードサービスで</Break>
					<br />
					お客様のカーライフを<Break>サポートします。</Break>
				</Lead>
				<Container>
					<Card>
						<CardTitle>
							レッカー<Break>サービス</Break>
						</CardTitle>
						<p>
							お車が走行できなくなった場合レッカー＆代車サービスを無料で行います。
						</p>
					</Card>
					<Card>
						<CardTitle>
							バッテリー<Break>あがり</Break>
						</CardTitle>
						<p>バッテリーあがりの場合、始動のお手伝いをします。</p>
					</Card>
					<Card>
						<CardTitle>
							クイック<Break>サービス</Break>
						</CardTitle>
						<p>故障事故の応急処置を行います。</p>
					</Card>
					<Card>
						<CardTitle>
							スペアタイヤ<Break>交換</Break>
						</CardTitle>
						<p>タイヤがパンクした場合、スペアタイヤとの交換作業を行います。</p>
					</Card>
					<Card>
						<CardTitle>鍵開け</CardTitle>
						<p>車にキーをとじ込んだ場合、解錠作業を行います。</p>
					</Card>
					<Card>
						<CardTitle>ガス欠</CardTitle>
						<p>ガス欠の場合、現地で給油を行います。</p>
					</Card>
				</Container>
			</Wrapper>
			<Img
				src={RoadserviceImg1}
				srcSet={`${RoadserviceImg1} 1x, ${RoadserviceImg1_2x} 2x`}
				alt="ハイエースの上に乗っている写真"
				width="438"
				height="648"
			/>
		</Outer>
	);
};
export default HomeRoadService;
