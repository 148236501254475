import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
import { AttentionFont } from '../style';
const v = styleVariables.var;
import Logo from '../../img/logo.svg';

const Outer = styled.footer`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 50px 0;
	background-color: ${v.color_main};
	@media ${v.media_md} {
		flex-direction: row;
		padding: 100px 0;
	}
`;
const LogoImg = styled.img`
	width: 180px;
	height: auto;
	margin-bottom: 30px;
	@media ${v.media_lg} {
		margin-bottom: 0;
		margin-right: 50px;
	}
`;
const Wrapper = styled.div`
	${AttentionFont}
	text-align: center;
	@media ${v.media_md} {
		text-align: left;
	}
`;
const CopyRights = styled.p`
	margin-top: 20px;
`;

const FooterCopyrights = () => {
	return (
		<Outer>
			<LogoImg
				src={Logo}
				alt="Original Work Shop Fate"
				width="205"
				height="88"
			/>
			<Wrapper>
				<HashLink smooth to="privacypolicy#">
					プライバシーポリシー
				</HashLink>
				<CopyRights>
					<small>CopyRights 2022 Original Work Shop Fate</small>
				</CopyRights>
			</Wrapper>
		</Outer>
	);
};
export default FooterCopyrights;
