import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import HomeFirstview from '../components/home/home-firstview';
import HomeConcept from '../components/home/home-concept';
import HomeAbout from '../components/home/home-about';
import HomeAchievement from '../components/home/home-achievement';
import HomeRoadservice from '../components/home/home-roadservice';
import HomeMovie from '../components/home/home-movie';

const Home = () => {
	smoothscroll.polyfill();
	return (
		<main>
			<HomeFirstview />
			<HomeConcept />
			<HomeAbout />
			<HomeAchievement />
			<HomeRoadservice />
			<HomeMovie />
		</main>
	);
};
export default Home;
