import React from 'react';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;
import {
	UnderOuter,
	OuterPadding,
	AttentionFont,
	TitleFont,
	Break,
} from '../components/style';
import AtmarkImg from '../img/insurance/atmark.png';
import InsuranceImg1 from '../img/insurance/insurance_insurance_01.png';

const Outer = styled.main`
	/* underouterでpaddintop指定するため先にOuterpadding指定 */
	${OuterPadding}
	${UnderOuter}
	max-width: 1000px;
	margin: 0 auto;
	margin: 0 auto 100px;
	text-align: center;
	@media ${v.media_md} {
		margin-bottom: 200px;
	}
`;
const Wrapper = styled.div`
	margin-bottom: 30px;
	p:not(:last-child) {
		margin-bottom: 10px;
	}
	& > img {
		width: 100%;
		max-width: 250px;
	}
	ul {
		margin-bottom: 10px;
		li {
			margin-bottom: 5px;
		}
	}
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;
const Lead = styled.p`
	${TitleFont}
	&:not(:last-child) {
		margin-bottom: 30px;
	}
`;
const At = styled.img`
	width: 1.4rem;
	height: auto;
	vertical-align: middle;
	@media ${v.media_md} {
		width: 1.8rem;
	}
`;
const Attention = styled.span`
	${AttentionFont}
`;

const Insurance = () => {
	return (
		<Outer>
			<Wrapper>
				<Lead>あなたにベスト</Lead>
				<Lead>こころにベスト</Lead>
				<Lead>みらいにベスト</Lead>
				<Lead>そんな保険見つけます</Lead>
			</Wrapper>
			<Wrapper>
				<img src={InsuranceImg1} alt="" width="250" height="250" />
			</Wrapper>
			<Wrapper>
				<p>ほけんのベストが大事にしていること</p>
				<p>
					それはしっかりと信頼関係を築いてから
					<br />
					お客様にとってベストな<Break>保険の契約を結ぶこと</Break>
				</p>
			</Wrapper>
			<Wrapper>
				<p>
					あなたの大切な誰かを守るために
					<br />
					ファイナンシャルプランナー・<Break>相続診断士等の有資格者が</Break>
					<br />
					保険を通して素敵なミライを<Break>一緒に設計していきます</Break>
				</p>
			</Wrapper>
			<Wrapper>
				<p>
					お問い合わせは以下から<Break>随時受け付けております</Break>
				</p>
				<ul>
					<li>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.facebook.com/hokenbest/"
						>
							Facebookメッセージ
						</a>
					</li>
					<li>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.instagram.com/hoken_best/"
						>
							Instagramダイレクトメッセージ
						</a>
					</li>
					<li>
						メール(hokennobest
						<At src={AtmarkImg} alt="@" width="14" height="14" />
						gmail.com)
					</li>
				</ul>
				<Attention>
					※保険のご相談については
					<Break>お問い合わせ後の完全予約制となりますが</Break>
					<br />
					常時事務所にはおりますので
					<Break>どうぞお気軽に遊びにきてください</Break>
				</Attention>
			</Wrapper>
			<Wrapper>
				<p>事務所</p>
				<p>Original Work Shop Fate内</p>
			</Wrapper>
			<Wrapper>
				<p>取り扱い保険会社</p>
				<p>
					〈生命保険〉
					<br />
					三井住友海上あいおい生命保険会社
				</p>
				<p>
					〈損害保険〉
					<br />
					あいおいニッセイ同和損保
				</p>
			</Wrapper>
		</Outer>
	);
};
export default Insurance;
