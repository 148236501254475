import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import {
	OuterStyle,
	Break,
	OuterPadding,
	AttentionFont,
	TitleFont,
	LeadFont,
	BtnStyle,
} from '../style';
import { scrollWithOffset } from '../scrollWithOffset';
import AboutImg1 from '../../img/home/home_about_01.svg';
import AboutImg2 from '../../img/home/home_about_02.svg';
import AboutImg3 from '../../img/home/home_about_03.svg';
import AboutImg4 from '../../img/home/home_about_04.svg';

const Outer = styled.div`
	${OuterPadding}
	${OuterStyle}
	text-align: center;
`;
const Lead = styled.p`
	margin-bottom: 20px;
	${LeadFont}
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;
const Wrapper = styled.div`
	width: 100%;
	max-width: 1050px;
	margin: 0 auto;
	@media ${v.media_sm} {
		display: flex;
		justify-content: center;
		align-items: stretch;
		flex-wrap: wrap;
	}
`;
const Card = styled.div`
	width: 98%;
	max-width: 350px;
	padding: 30px 10px;
	margin: 0 auto 30px;
	background-color: ${v.color_base};
	box-shadow: 0px 7px 29px ${v.color_main};
	border-radius: 30px;
	${AttentionFont}
	p:not(:last-child) {
		margin-bottom: 5px;
	}
	@media ${v.media_sm} {
		width: 47%;
		max-width: 500px;
	}
	@media ${v.media_md} {
		max-width: 500px;
		padding: 50px 20px;
	}
`;
const CardTitle = styled.h2`
	margin-bottom: 10px;
	${TitleFont}
	@media ${v.media_md} {
		margin-bottom: 30px;
	}
`;
const Img = styled.img`
	width: 80%;
	max-height: 150px;
	margin-bottom: 20px;
	@media ${v.media_md} {
		width: 60%;
		max-height: 200px;
		margin-bottom: 30px;
	}
`;
const Btn = styled(HashLink)`
	${BtnStyle}
	margin: 20px auto 0;
	@media ${v.media_md} {
		margin: 30px auto 0;
	}
`;
const HomeAbout = () => {
	return (
		<Outer id="about">
			<Lead>
				お客様の想いを<Break>カタチにするために</Break>
				<br />
				Fateには4つの<Break>こだわりがあります。</Break>
			</Lead>
			<Wrapper>
				<Card>
					<CardTitle>楽しさ</CardTitle>
					<Img
						src={AboutImg1}
						alt="楽しいイメージ画像"
						width="212"
						height="150"
					/>
					<p>
						理想の車が見つからない
						<br />
						そんな時はFateに<Break>遊びに来てください。</Break>
					</p>
					<p>
						毎日乗る「車」だから、<Break>「楽しい」ほうがいい。</Break>
					</p>
					<p>
						Fateは、<Break>あなただけの理想の1台を</Break>
						<br />
						叶える魔法が使えます。
					</p>
					<p>
						そして、お客様が「楽しい」と<Break>感じてくれたら</Break>
						<br />
						私たちも「楽しい」のです。
					</p>
				</Card>
				<Card>
					<CardTitle>安全</CardTitle>
					<Img
						src={AboutImg2}
						alt="安全のイメージ画像"
						width="212"
						height="150"
					/>
					<p>
						Fateがお客様に<Break>一番にご提案する車。</Break>
						<br />
						それが「安全」です。
					</p>
					<p>
						「お客様の命を預かる責任」
						<br />
						堅い言葉かもしれませんが、
						<br />
						これが基本であり原則です。
					</p>
					<p>
						楽しいことを追求する一方で
						<br />
						「安全」にも徹底して<Break>こだわります。</Break>
					</p>
				</Card>
				<Card>
					<CardTitle>品質</CardTitle>
					<Img
						src={AboutImg3}
						alt="品質のイメージ画像"
						width="212"
						height="150"
					/>
					<p>
						オイル一つから、<Break>ドレスアップパーツまで</Break>
						<br />
						品質の良いものしか<Break>取り扱いしません。</Break>
					</p>
					<p>
						塗装の仕上がりも、<Break>部品の取り付けにも</Break>
						<br />
						ディーラー以上の独自の基準で
						<br />
						厳しくチェックしたものしか<Break>納車しません。</Break>
					</p>
				</Card>
				<Card>
					<CardTitle>安心</CardTitle>
					<Img
						src={AboutImg4}
						alt="安心のイメージ画像"
						width="212"
						height="150"
					/>
					<p>
						ロードサービスは<Break>いつ必要になるか</Break>
						<Break>わからないもの。</Break>
					</p>
					<p>
						Fateは、<Break>24時間事故車・故障車の</Break>
						<br />
						ロードサービスを<Break>サポートします。</Break>
					</p>
					<p>
						急な車のトラブルにも頼れる<Break>カーショップでありたい。</Break>
					</p>
					<p>
						Fateだから出来るサービスを<Break>お約束します。</Break>
					</p>
					<Btn smooth to="#roadservice" scroll={(el) => scrollWithOffset(el)}>
						ロードサービスについて
					</Btn>
				</Card>
			</Wrapper>
		</Outer>
	);
};
export default HomeAbout;
