import React from 'react';
import styled from 'styled-components';
import { OuterStyle, OuterPadding } from '../style';
import Video_01 from '../../img/home/home_movie.mp4';

const Outer = styled.div`
	${OuterStyle}
	${OuterPadding}
`;
const Video = styled.div`
	width: 100%;
	margin: 0 auto;
	text-align: center;
	video {
		width: 100%;
		max-width: 1000px;
	}
`;

const HomeMovie = () => {
	return (
		<Outer>
			<Video>
				<video controls autoPlay playsInline muted src={Video_01}></video>
			</Video>
		</Outer>
	);
};
export default HomeMovie;
