import React, { useEffect } from 'react';
import { LuminousGallery } from 'luminous-lightbox';
import 'luminous-lightbox/dist/luminous-basic.min.css';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { OuterStyle, OuterPadding, TitleStyle, Break } from '../style';

// 1
import PhotoVan1_view from '../../img/gallery/gallery_van_view_01.jpg';
import PhotoVan1_view_SP from '../../img/gallery/gallery_van_view_01-SP.jpg';
import PhotoVan1_zoom from '../../img/gallery/gallery_van_zoom_01.jpg';
// 2
import PhotoVan2_view from '../../img/gallery/gallery_van_view_02.jpg';
import PhotoVan2_view_SP from '../../img/gallery/gallery_van_view_02-SP.jpg';
import PhotoVan2_zoom from '../../img/gallery/gallery_van_zoom_02.jpg';
// 3
import PhotoVan3_view from '../../img/gallery/gallery_van_view_03.jpg';
import PhotoVan3_view_SP from '../../img/gallery/gallery_van_view_03-SP.jpg';
import PhotoVan3_zoom from '../../img/gallery/gallery_van_zoom_03.jpg';
// 4
import PhotoVan4_view from '../../img/gallery/gallery_van_view_04.jpg';
import PhotoVan4_view_SP from '../../img/gallery/gallery_van_view_04-SP.jpg';
import PhotoVan4_zoom from '../../img/gallery/gallery_van_zoom_04.jpg';
// 5
import PhotoVan5_view from '../../img/gallery/gallery_van_view_05.jpg';
import PhotoVan5_view_SP from '../../img/gallery/gallery_van_view_05-SP.jpg';
import PhotoVan5_zoom from '../../img/gallery/gallery_van_zoom_05.jpg';
// 6
import PhotoVan6_view from '../../img/gallery/gallery_van_view_06.jpg';
import PhotoVan6_view_SP from '../../img/gallery/gallery_van_view_06-SP.jpg';
import PhotoVan6_zoom from '../../img/gallery/gallery_van_zoom_06.jpg';
// 7
import PhotoVan7_view from '../../img/gallery/gallery_van_view_07.jpg';
import PhotoVan7_view_SP from '../../img/gallery/gallery_van_view_07-SP.jpg';
import PhotoVan7_zoom from '../../img/gallery/gallery_van_zoom_07.jpg';
// カスタムカー
// 1
import PhotoCustom1_view from '../../img/gallery/gallery_custom_view_01.jpg';
import PhotoCustom1_view_SP from '../../img/gallery/gallery_custom_view_01-SP.jpg';
import PhotoCustom1_zoom from '../../img/gallery/gallery_custom_zoom_01.jpg';
// 2
import PhotoCustom2_view from '../../img/gallery/gallery_custom_view_02.jpg';
import PhotoCustom2_view_SP from '../../img/gallery/gallery_custom_view_02-SP.jpg';
import PhotoCustom2_zoom from '../../img/gallery/gallery_custom_zoom_02.jpg';
// 3
import PhotoCustom3_view from '../../img/gallery/gallery_custom_view_03.jpg';
import PhotoCustom3_view_SP from '../../img/gallery/gallery_custom_view_03-SP.jpg';
import PhotoCustom3_zoom from '../../img/gallery/gallery_custom_zoom_03.jpg';
// 4
import PhotoCustom4_view from '../../img/gallery/gallery_custom_view_04.jpg';
import PhotoCustom4_view_SP from '../../img/gallery/gallery_custom_view_04-SP.jpg';
import PhotoCustom4_zoom from '../../img/gallery/gallery_custom_zoom_04.jpg';
// 5
import PhotoCustom5_view from '../../img/gallery/gallery_custom_view_05.jpg';
import PhotoCustom5_view_SP from '../../img/gallery/gallery_custom_view_05-SP.jpg';
import PhotoCustom5_zoom from '../../img/gallery/gallery_custom_zoom_05.jpg';
// 6
import PhotoCustom6_view from '../../img/gallery/gallery_custom_view_06.jpg';
import PhotoCustom6_view_SP from '../../img/gallery/gallery_custom_view_06-SP.jpg';
import PhotoCustom6_zoom from '../../img/gallery/gallery_custom_zoom_06.jpg';
// 7
import PhotoCustom7_view from '../../img/gallery/gallery_custom_view_07.jpg';
import PhotoCustom7_view_SP from '../../img/gallery/gallery_custom_view_07-SP.jpg';
import PhotoCustom7_zoom from '../../img/gallery/gallery_custom_zoom_07.jpg';
// 8
import PhotoCustom8_view from '../../img/gallery/gallery_custom_view_08.jpg';
import PhotoCustom8_view_SP from '../../img/gallery/gallery_custom_view_08-SP.jpg';
import PhotoCustom8_zoom from '../../img/gallery/gallery_custom_zoom_08.jpg';
// 9
import PhotoCustom9_view from '../../img/gallery/gallery_custom_view_09.jpg';
import PhotoCustom9_view_SP from '../../img/gallery/gallery_custom_view_09-SP.jpg';
import PhotoCustom9_zoom from '../../img/gallery/gallery_custom_zoom_09.jpg';

const Outer = styled.ul`
	${OuterPadding}
	${OuterStyle}
	margin-bottom: 100px;
	text-align: center;
	@media ${v.media_md} {
		margin-bottom: 200px;
	}
`;
const Container = styled.div`
	margin-bottom: 100px;
	@media ${v.media_md} {
		margin-bottom: 200px;
	}
`;
const Wrapper = styled.ul`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 5px;
	margin-bottom: 50px;
	@media ${v.media_md} {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 15px;
		margin-bottom: 100px;
	}
	a.luminous {
		cursor: zoom-in;
	}
`;
const Title = styled.h2`
	${TitleStyle}
	margin-bottom: 15px;
	@media ${v.media_md} {
		margin-bottom: 30px;
	}
`;
const Lead = styled.p`
	margin-bottom: 20px;
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;
const Img = styled.img`
	width: 100%;
	height: auto;
`;

const GalleryPhoto = () => {
	useEffect(() => {
		// 画像のalt属性の値をキャプションにセットする
		let options = {
			caption: function (trigger) {
				return trigger.querySelector('img').getAttribute('alt');
			},
		};
		const luminousTrigger = document.querySelectorAll('.luminous');
		if (luminousTrigger !== null) {
			//LuminousGalleryで第二引数galleryOpts（ギャラリースタイル専用のオプション）を特に指定しない場合は {} でOK
			new LuminousGallery(luminousTrigger, {}, options);
		}
	}, []);
	return (
		<Outer id="photoglaph">
			{/* 横画像 */}
			<Container id="van">
				<Title>バンライフ</Title>
				<Lead>
					車で暮らすことを楽しめるカスタムで
					<Break>あなたのバンライフを全力で応援します。</Break>
					<br />
					自分だけの相棒と自由気ままに<Break>どこまでも遠くへ。</Break>
				</Lead>
				<Wrapper>
					{/* 1 */}
					<li>
						<a
							className="luminous"
							href={PhotoVan1_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoVan1_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoVan1_view_SP}
									alt="広々とした居住空間で快適なバンライフを過ごせます"
									width="250"
									height="167"
								/>
							</picture>
						</a>
					</li>
					{/* 2 */}
					<li>
						<a
							className="luminous"
							href={PhotoVan2_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoVan2_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoVan2_view_SP}
									alt="温かみのある木材でぬくもり溢れる車内を演出します"
									width="250"
									height="167"
								/>
							</picture>
						</a>
					</li>
					{/* 3 */}
					<li>
						<a
							className="luminous"
							href={PhotoVan3_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoVan3_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoVan3_view_SP}
									alt="引き出すことで車外でも使える机や小物の収納も完備しています"
									width="250"
									height="167"
								/>
							</picture>
						</a>
					</li>
					{/* 4 */}
					<li>
						<a
							className="luminous"
							href={PhotoVan4_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoVan4_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoVan4_view_SP}
									alt="冷蔵庫も車内に搭載できます"
									width="250"
									height="167"
								/>
							</picture>
						</a>
					</li>
					{/* 5 */}
					<li>
						<a
							className="luminous"
							href={PhotoVan5_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoVan5_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoVan5_view_SP}
									alt="ベッド下の引き出しには大きい物も収納可能です"
									width="250"
									height="167"
								/>
							</picture>
						</a>
					</li>
					{/* 6 */}
					<li>
						<a
							className="luminous"
							href={PhotoVan6_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoVan6_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoVan6_view_SP}
									alt="灯りも完備しているため夜でも問題なくくつろげます"
									width="250"
									height="167"
								/>
							</picture>
						</a>
					</li>
					{/* 7 */}
					<li>
						<a
							className="luminous"
							href={PhotoVan7_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoVan7_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoVan7_view_SP}
									alt="ルーフについているソーラーパネルから車内の電力を供給できます"
									width="250"
									height="167"
								/>
							</picture>
						</a>
					</li>
				</Wrapper>
			</Container>
			{/* 縦画像 */}
			<Container id="custom">
				<Title>カスタムカー制作</Title>
				<Lead>
					世界でたった一台の<Break>世界で一番カッコいい車を</Break>
					<br />
					すべてのお客様にお届けするために<Break>Fateは妥協しません。</Break>
					<br />
					車づくりの道のりに近道はないからこそ、
					<br />
					納得の一台が仕上がったとき<Break>感動も大きいのです。</Break>
				</Lead>
				<Wrapper>
					{/* 1 */}
					<li>
						<a
							className="luminous"
							href={PhotoCustom1_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoCustom1_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoCustom1_view_SP}
									alt="どんな街並みにも映える車を"
									width="250"
									height="334"
								/>
							</picture>
						</a>
					</li>
					{/* 2 */}
					<li>
						<a
							className="luminous"
							href={PhotoCustom2_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoCustom2_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoCustom2_view_SP}
									alt="細かい装飾にも手を抜かずどこから見てもカッコいい車に"
									width="250"
									height="334"
								/>
							</picture>
						</a>
					</li>
					{/* 3 */}
					<li>
						<a
							className="luminous"
							href={PhotoCustom3_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoCustom3_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoCustom3_view_SP}
									alt="心を込めた「1mm」へのこだわりでまるで芸術作品のようなドレスアップを"
									width="250"
									height="334"
								/>
							</picture>
						</a>
					</li>
					{/* 4 */}
					<li>
						<a
							className="luminous"
							href={PhotoCustom4_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoCustom4_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoCustom4_view_SP}
									alt="外車のドレスアップもお任せください"
									width="250"
									height="334"
								/>
							</picture>
						</a>
					</li>
					{/* 5 */}
					<li>
						<a
							className="luminous"
							href={PhotoCustom5_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoCustom5_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoCustom5_view_SP}
									alt="匠の業で「魅せるオーディオカー」を創ります"
									width="250"
									height="334"
								/>
							</picture>
						</a>
					</li>
					{/* 6 */}
					<li>
						<a
							className="luminous"
							href={PhotoCustom6_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoCustom6_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoCustom6_view_SP}
									alt="Fateなら家族みんなが「楽しい」車を創ることだってできます"
									width="250"
									height="334"
								/>
							</picture>
						</a>
					</li>
					{/* 7 */}
					<li>
						<a
							className="luminous"
							href={PhotoCustom7_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoCustom7_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoCustom7_view_SP}
									alt="美しい電飾でラグジュアリーな空間を演出します"
									width="250"
									height="334"
								/>
							</picture>
						</a>
					</li>
					{/* 8 */}
					<li>
						<a
							className="luminous"
							href={PhotoCustom8_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoCustom8_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoCustom8_view_SP}
									alt="見えないところも美しくこだわり抜きます"
									width="250"
									height="334"
								/>
							</picture>
						</a>
					</li>
					{/* 9 */}
					<li>
						<a
							className="luminous"
							href={PhotoCustom9_zoom}
							aria-label="クリックで拡大"
						>
							<picture>
								<source srcSet={PhotoCustom9_view} media={`${v.media_sm}`} />
								<Img
									src={PhotoCustom9_view_SP}
									alt="二輪車のカスタムも承っております"
									width="250"
									height="334"
								/>
							</picture>
						</a>
					</li>
				</Wrapper>
			</Container>
		</Outer>
	);
};
export default GalleryPhoto;
