import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { TxtFont } from './components/style';

import Gnav from './components/gnav/gnav';
import Home from './pages/home.jsx';
import Gallery from './pages/gallery.jsx';
import Insurance from './pages/insurance';
import Access from './pages/access';
import Privacypolicy from './pages/privacypolicy.jsx';
import Footer from './components/footer/footer';

// データ
import styleVariables from './style-variables.json';

// スタイル
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import Notfound from './pages/notfound';
const v = styleVariables.var;

const GlobalStyle = createGlobalStyle`
  ${reset}
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html{
    width: 100%;
    background-color: ${v.color_base};
    font-family: ${v.font_fam};
    font-size: 62.5%;
    scroll-behavior: smooth;
  }
  body{
    line-height: 1.5;
    letter-spacing: .15rem;
    font-weight: ${v.font_weight_normal}; 
    color: ${v.color_txt};
    ${TxtFont}
    /* Luminousが別コンポーネントとして開くためbodyに指定 */
    .lum-lightbox{
		  z-index: 9999;
    }
    @media (max-width: 460px){
      .lum-lightbox-inner img {
        max-height: 100% !important;
        max-width: 100% !important;
      }
      /* キャプションの位置指定 */
      /* 拡大画像をローディングしている時にキャプション位置がずれるため */
      .lum-lightbox-inner .lum-lightbox-caption {
        left: 0;
        bottom: 15%;
      }
      /* 矢印ナビが大きすぎると感じたら */
      .lum-gallery-button:after {
        width: 6vw;
        height: 6vw;
      }
    }
  }
  /* windowsの小さい文字がジャぎる問題解消用 */
  p, h1, h2, h3, a{
    transform: rotate(0.05deg);
  }
  a{
    text-decoration: underline;
    color: ${v.color_txt};
    @media all and (any-hover: hover) {
      &:hover {
        text-decoration:none;
      }
    }
  }
  /* img下にできるmargin解消 */
  img{
    vertical-align:top;
  }
`;

ReactDOM.render(
	// <React.StrictMode>
	<React.Fragment>
		<GlobalStyle />
		<BrowserRouter>
			<Gnav />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/gallery" element={<Gallery />} />
				<Route path="/insurance" element={<Insurance />} />
				<Route path="/access" element={<Access />} />
				<Route path="/privacypolicy" element={<Privacypolicy />} />
				<Route path="*" element={<Notfound />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	</React.Fragment>,
	// </React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
