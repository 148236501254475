import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;

const GoogleMap = styled.div`
	position: relative;
	width: 100%;
	height: 350px;
	iframe {
		width: 100%;
		height: 100%;
	}
	@media ${v.media_md} {
	}
`;

const FooterAccess = () => {
	return (
		<GoogleMap>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.364385695791!2d134.2239342152831!3d34.06017222475944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3553ba9efaf61eb3%3A0x9874633033dc9ffb!2sOriginal%20Work%20Shop%20Fate!5e0!3m2!1sja!2sjp!4v1646896339957!5m2!1sja!2sjp"
				width="600"
				height="400"
				style={{ border: 0 }}
				allowFullScreen=""
				loading="lazy"
			></iframe>
		</GoogleMap>
	);
};
export default FooterAccess;
