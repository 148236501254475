import React from 'react';
import { IconFb, IconInsta, IconPinte } from './icon';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;
import { BtnStyle, BtnOuterStyle } from './style';

const BtnOuter = styled.div`
	${BtnOuterStyle}
	max-width: 990px;
	margin: 0 auto;
`;
const Btn = styled.a`
	${BtnStyle}
	margin: 0 auto;
	&:not(:last-child) {
		margin-bottom: 20px;
	}
	@media ${v.media_sm} {
		width: 31%;
		max-width: 300px;
		&:not(:last-child) {
			margin-bottom: 0;
		}
	}
`;

const FooterContact = () => {
	return (
		<BtnOuter>
			<Btn target="_blank" rel="noreferrer" href={v.url_fb}>
				<IconFb />
				Facebook
			</Btn>
			<Btn target="_blank" rel="noreferrer" href={v.url_insta}>
				<IconInsta />
				Instagram
			</Btn>
			<Btn target="_blank" rel="noreferrer" href={v.url_pinte}>
				<IconPinte />
				Pinterest
			</Btn>
		</BtnOuter>
	);
};
export default FooterContact;
