import React from 'react';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;
import { UnderOuter, OuterPadding, TitleStyle } from '../components/style';

const Outer = styled.main`
	${UnderOuter}
	margin: 0 auto 100px;
	text-align: center;
	font-size: 1.4rem;
	@media ${v.media_md} {
		margin-bottom: 200px;
		font-size: 1.6rem;
	}
`;
const Wrapper = styled.div`
	${OuterPadding}
	max-width: 1000px;
	margin: 0 auto;
	text-align: left;
`;
const GoogleMap = styled.div`
	position: relative;
	width: 100%;
	height: 400px;
	margin-bottom: 30px;
	iframe {
		width: 100%;
		height: 100%;
	}
	@media ${v.media_md} {
	}
`;
const Title = styled.h1`
	${TitleStyle}
	margin-bottom: 30px;
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;
const StyledSection = styled.section`
	margin-top: 30px;
	@media ${v.media_md} {
		text-align: center;
	}
`;
const SectionTitle = styled.h2`
	margin-bottom: 5px;
	font-size: 1.6rem;
	@media ${v.media_md} {
		font-size: 2rem;
	}
`;

const Privacypolicy = () => {
	return (
		<Outer>
			<Title>アクセス</Title>
			<Wrapper>
				<GoogleMap>
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.364385695791!2d134.2239342152831!3d34.06017222475944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3553ba9efaf61eb3%3A0x9874633033dc9ffb!2sOriginal%20Work%20Shop%20Fate!5e0!3m2!1sja!2sjp!4v1646896339957!5m2!1sja!2sjp"
						width="600"
						height="400"
						style={{ border: 0 }}
						allowFullScreen=""
						loading="lazy"
					></iframe>
				</GoogleMap>
				<StyledSection>
					<SectionTitle>関西方面から</SectionTitle>
					<p>
						高松道板野IC→徳島道藍住IC→徳島道脇町ICを降りて国道193号線を南に→国道192号線を東に約15分
					</p>
				</StyledSection>
				<StyledSection>
					<SectionTitle>中国方面から</SectionTitle>
					<p>高松道高松西IC→国道193号線を南に→国道192号線を東に約15分</p>
				</StyledSection>
				<StyledSection>
					<a
						href="https://g.page/FateCustom?share"
						target="_blank"
						rel="noopener noreferrer"
					>
						GoogleMapで確認
					</a>
				</StyledSection>
			</Wrapper>
		</Outer>
	);
};
export default Privacypolicy;
