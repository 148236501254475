import React from 'react';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;
import {
	UnderOuter,
	OuterPadding,
	TitleFont,
	AttentionFont,
} from '../components/style';
import { HashLink } from 'react-router-hash-link';

const Outer = styled.main`
	/* underouterでpaddintop指定するため先にOuterpadding指定 */
	${OuterPadding}
	${UnderOuter}
	max-width: 1000px;
	margin: 0 auto;
	margin: 0 auto 100px;
	text-align: center;
	@media ${v.media_md} {
		margin-bottom: 200px;
	}
`;
const Wrapper = styled.div`
	margin-bottom: 30px;
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;
const Lead = styled.p`
	${TitleFont}
`;
const Attention = styled.p`
	${AttentionFont}
	margin-bottom: 20px;
`;

const Notfound = () => {
	return (
		<Outer>
			<Wrapper>
				<Lead>ページが見つかりません</Lead>
				<Attention>お探しのページは見つかりませんでした。</Attention>
				<HashLink smooth to="/">
					トップページに戻る
				</HashLink>
			</Wrapper>
		</Outer>
	);
};
export default Notfound;
