import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import {
	OuterStyle,
	BtnStyle,
	BtnOuterStyle,
	LeadFont,
	AttentionFont,
	OuterPadding,
	Break,
} from '../style';
import { IconPhone } from '../icon';
import ContactImg1 from '../../img/footer_contact_01.jpg';
import ContactImg1_2x from '../../img/footer_contact_01@2x.jpg';

const Outer = styled.div`
	${OuterStyle}
	${OuterPadding}
`;
const Wrapper = styled.div`
	width: fit-content;
	margin: 0 auto 30px;
	@media ${v.media_md} {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 50px;
	}
`;

const Img = styled.img`
	width: 100%;
	height: auto;
	max-width: 500px;
	margin-bottom: 10px;
	@media ${v.media_md} {
		width: 50%;
		margin-bottom: 0;
		margin-right: 30px;
	}
	@media ${v.media_lg} {
		width: 100%;
		margin-right: 60px;
	}
`;
const DataOuter = styled.div`
	display: flex;
	flex-wrap: wrap;
	dd {
		margin-left: 0.5rem;
	}
	@media ${v.media_md} {
		display: flex;
		margin-bottom: 10px;
		dd {
			margin-left: 1.5rem;
		}
	}
`;
const BtnOuter = styled.div`
	${BtnOuterStyle}
`;
const Btn = styled.a`
	${BtnStyle}
	&:first-child {
		margin-bottom: 20px;
	}
	@media ${v.media_sm} {
		width: 47.5%;
		max-width: 250px;
		&:first-child {
			margin-bottom: 0;
			margin-right: 5%;
		}
	}
	@media ${v.media_md} {
		max-width: 300px;
		&:first-child {
			margin-right: 50px;
		}
	}
`;
const Text = styled.div`
	${LeadFont}
	margin-bottom: 20px;
	text-align: center;
	p {
		margin-bottom: 5px;
	}
	@media ${v.media_md} {
		margin-bottom: 30px;
	}
`;
const Attention = styled.p`
	${AttentionFont}
`;
const FooterContact = () => {
	return (
		<Outer id="contact">
			<Wrapper>
				<Img
					src={ContactImg1}
					srcSet={`${ContactImg1} 1x, ${ContactImg1_2x} 2x`}
					alt="店舗外観"
					width="500"
					height="280"
				/>
				<div>
					<dl>
						<DataOuter>
							<dt>住所</dt>
							<dd>徳島県吉野川市山川町川田1353</dd>
						</DataOuter>
						<DataOuter>
							<dt>営業時間</dt>
							<dd>10:00-19:00</dd>
						</DataOuter>
						<DataOuter>
							<dt>定休日</dt>
							<dd>木曜日、第二・第四日曜日</dd>
						</DataOuter>
						<DataOuter>
							<dt>電話番号</dt>
							<dd>
								<a href="tel:0883-42-4583">0883-42-4583</a>
							</dd>
						</DataOuter>
						<DataOuter>
							<dt>FAX</dt>
							<dd>0883-42-4893</dd>
						</DataOuter>
					</dl>
					<Attention>
						営業時間・定休日は変更となる場合がございますので、<Break></Break>
						<br />
						ご来店前に店舗にご確認ください。
					</Attention>
				</div>
			</Wrapper>
			<div>
				<Text>
					<p>
						お問い合わせはお電話にて
						<Break>承っております</Break>
					</p>
					<Attention>
						※オイル交換のみWebからの<Break>ご予約を受け付けております</Break>
					</Attention>
				</Text>
				<BtnOuter>
					<Btn href="tel:0883-42-4583">
						<IconPhone />
						電話をかける
					</Btn>
					<Btn
						target="_blank"
						rel="noreferrer"
						href="https://reserva.be/fatecar/reserve?mode=service_staff&search_evt_no=edeJwzMTA2NDEGAAQoATA"
					>
						オイル交換のご予約
					</Btn>
				</BtnOuter>
			</div>
		</Outer>
	);
};
export default FooterContact;
