import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;

import FirstviewImg1 from '../../img/home/home_firstview_01.jpg';
import FirstviewImg1_2x from '../../img/home/home_firstview_01@2x.jpg';
import FirstviewImg1_SP from '../../img/home/home_firstview_01-SP.jpg';
import FirstviewImg1_SP_2x from '../../img/home/home_firstview_01-SP@2x.jpg';

const Outer = styled.div`
	width: 100%;
	margin: 80px auto 100px;
	@media ${v.media_lg} {
		margin-bottom: 150px;
	}
`;
const Wrapper = styled.div`
	width: 100%;
	padding: 0 20px;
	text-align: center;
	@media ${v.media_md} {
		padding: 0 30px;
	}
`;
const Img = styled.img`
	width: 100%;
	max-width: 1200px;
	height: auto;
`;

const HomeFirstview = () => {
	return (
		<Outer>
			<Wrapper>
				<picture>
					<source
						srcSet={`${FirstviewImg1} 1x, ${FirstviewImg1_2x} 2x`}
						media={`${v.media_sm}`}
					/>
					<Img
						src={FirstviewImg1_SP}
						srcSet={`${FirstviewImg1_SP} 1x, ${FirstviewImg1_SP_2x} 2x`}
						alt=""
						width="500"
						height="666"
					/>
				</picture>
			</Wrapper>
		</Outer>
	);
};
export default HomeFirstview;
