import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from '../scrollWithOffset';
import {
	OuterPadding,
	BtnOuterStyle,
	BtnStyle,
	TitleStyle,
	AttentionFont,
} from '../style';

const Outer = styled.div`
	${OuterPadding}
	width: 100%;
	max-width: 800px;
	text-align: center;
	margin: 0 auto 100px;
	@media ${v.media_md} {
		margin-bottom: 150px;
	}
`;
const Title = styled.h1`
	${TitleStyle}
	margin-bottom: 5px;
`;
const Lead = styled.p`
	${AttentionFont}
	margin-bottom: 20px;
	@media ${v.media_md} {
		margin-bottom: 30px;
	}
`;
const BtnOuter = styled.div`
	${BtnOuterStyle}
`;
const Btn = styled(HashLink)`
	${BtnStyle}
	&:first-child {
		margin-bottom: 20px;
	}
	@media ${v.media_sm} {
		&:first-child {
			margin-bottom: 0px;
			margin-right: 50px;
		}
	}
`;
const GalleryAbout = () => {
	return (
		<Outer>
			<Title>制作実績</Title>
			<Lead>※画像をクリックすると拡大します。</Lead>
			<BtnOuter>
				<Btn smooth to="#van" scroll={(el) => scrollWithOffset(el)}>
					バンライフ
				</Btn>
				<Btn smooth to="#custom" scroll={(el) => scrollWithOffset(el)}>
					カスタムカー制作
				</Btn>
			</BtnOuter>
		</Outer>
	);
};
export default GalleryAbout;
