import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { OuterStyle, Break, OuterPadding, LeadFont, TitleFont } from '../style';
import ConceptImg1 from '../../img/home/home_concept_01.svg';
import ConceptImg2 from '../../img/home/home_concept_02.svg';
import ConceptImg2_SP from '../../img/home/home_concept_02-SP.svg';

const Outer = styled.div`
	${OuterPadding}
	${OuterStyle}
	text-align: center;
	margin-bottom: 30px;
	&::after {
		content: url(${ConceptImg2_SP});
		display: block;
		width: 10px;
		height: auto;
		margin: 30px auto 0;
	}
	@media ${v.media_md} {
		margin-bottom: 50px;
		&::after {
			content: url(${ConceptImg2});
			width: 20px;
			margin: 50px auto 0;
		}
	}
`;
const Text = styled.div`
	margin-bottom: 30px;
	${LeadFont}
	P:not(:last-child) {
		margin-bottom: 15px;
	}
	@media ${v.media_md} {
		margin-bottom: 50px;
		P:not(:last-child) {
			margin-bottom: 30px;
		}
	}
`;
const Lead = styled.h2`
	margin-bottom: 30px;
	${TitleFont}
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;
const Img = styled.img`
	width: 70%;
	max-width: 250px;
	height: auto;
	@media ${v.media_md} {
		max-width: 350px;
	}
`;

const HomeConcept = () => {
	return (
		<Outer>
			<Text>
				<Lead>
					もっと遠くへ、<Break>相棒といっしょに</Break>
				</Lead>
				<p>
					「車に乗るために目的地を決める」
					<br />
					そんな居心地の良いクルマ創りを<Break>心がけています。</Break>
					<Break></Break>
				</p>
				<p>もっと遠くへ行きたくなる。</p>
				<p>いつも一緒にいたくなる。</p>
				<p>
					あなたの生活の一部になる
					<br />
					そんな相棒をFateで<Break>創ってみませんか？</Break>
				</p>
			</Text>
			<Img src={ConceptImg1} alt="バンのイラスト" width="350" height="199.48" />
		</Outer>
	);
};
export default HomeConcept;
