import React from 'react';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;
import {
	UnderOuter,
	OuterPadding,
	TitleStyle,
	AttentionFont,
} from '../components/style';

const Outer = styled.main`
	${UnderOuter}
	margin: 0 auto 100px;
	text-align: center;
	font-size: 1.4rem;
	@media ${v.media_md} {
		margin-bottom: 200px;
		font-size: 1.6rem;
	}
`;
const Wrapper = styled.div`
	${OuterPadding}
	max-width: 1000px;
	margin: 0 auto;
	text-align: left;
`;
const Title = styled.h1`
	${TitleStyle}
	margin-bottom: 30px;
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;
const StyledSection = styled.section`
	margin-top: 30px;
	@media ${v.media_md} {
		margin-top: 30px;
	}
	li {
		&::before {
			content: '・';
		}
		ul {
			margin-left: 1.4rem;
		}
	}
`;
const SectionTitle = styled.h2`
	margin-bottom: 5px;
	font-size: 1.6rem;
	@media ${v.media_md} {
		font-size: 2rem;
	}
`;
const Container = styled.div`
	& > p {
		margin: 30px 0 10px;
	}
`;
const Attention = styled.span`
	${AttentionFont}
	display: block;
	text-transform: uppercase;
`;

const Privacypolicy = () => {
	return (
		<Outer>
			<Title>プライバシーポリシー</Title>
			<Wrapper>
				<p>
					本ウェブサイトの名称は、「Original Work Shop
					Fate」です。（以下「当サイト」といいます。）
				</p>
				<p>
					当サイトにおける個人情報の取扱いについて、以下の通りプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
				</p>
				<StyledSection>
					<SectionTitle>個人情報の定義</SectionTitle>
					<p>
						本ポリシーにおいて「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
					</p>
				</StyledSection>
				<StyledSection>
					<SectionTitle>個人情報の利用目的</SectionTitle>
					<p>
						当サイトでは、読者様からのお問い合わせ等を通じて、氏名、生年月日、住所、電話番号、メールアドレス等の個人情報をご提供いただく場合があります。その場合は、以下に示す利用目的のために、適正に利用するものと致します。
					</p>
					<ul>
						<li>お問い合わせ等に対応するため。</li>
						<li>お申し込みいただいたサービス等の提供のため。</li>
						<li>
							メールマガジン等の配信、セミナーやイベントのご案内等のため。
						</li>
						<li>当サイトが実施するアンケートへのご協力のお願いのため。</li>
						<li>商品や景品、プレゼント等の発送のため。</li>
						<li>
							当サイトのサービス向上・改善、新サービスを検討するための分析等を行うため。
						</li>
						<li>
							個人を識別できない形で統計データを作成し、当サイトおよび読者様の参考資料とするため。
						</li>
					</ul>
				</StyledSection>

				<StyledSection>
					<SectionTitle>個人情報の第三者提供</SectionTitle>
					<p>
						当サイトは次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
					</p>
					<ul>
						<li>
							人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
						</li>
						<li>
							公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
						</li>
						<li>
							国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
						</li>
						<li>
							予め次の事項を告知あるいは公表し、かつ当サイトが個人情報保護委員会に届出をしたとき
							<ul>
								<li>利用目的に第三者への提供を含むこと</li>
								<li>第三者に提供されるデータの項目</li>
								<li>第三者への提供の手段または方法</li>
								<li>
									本人の求めに応じて個人情報の第三者への提供を停止すること
								</li>
								<li>本人の求めを受け付ける方法</li>
								<li>
									前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
									<ul>
										<li>
											当サイトが利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
										</li>
										<li>
											合併その他の事由による事業の承継に伴って個人情報が提供される場合
										</li>
										<li>
											個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合
										</li>
									</ul>
								</li>
							</ul>
						</li>
					</ul>
				</StyledSection>
				<StyledSection>
					<SectionTitle>本ポリシーの変更</SectionTitle>
					<p>
						当サイトは、法令の制定や改正等により、本ポリシーを適宜見直し、予告なく変更する場合があります。本ポリシーの変更は、変更後の本ポリシーが当サイトに掲載された時点、またはその他の方法により変更後の本ポリシーが閲覧可能となった時点で有効になります。
					</p>
				</StyledSection>
				<StyledSection>
					<SectionTitle>Cookie（クッキー）について</SectionTitle>
					<p>
						当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
						このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくは
						<a
							target="_blank"
							rel="noreferrer"
							href="https://marketingplatform.google.com/about/analytics/terms/jp/"
						>
							「Googleアナリティクス利用規約」
						</a>
						をご確認ください。
					</p>
				</StyledSection>
				<Container>
					<p>（2022年2月15日 制定）</p>
					<div>
						<p>運営者：Original Work Shop Fate</p>
						<p>お問い合わせ：Original Work Shop Fate（0883-42-4583）</p>
					</div>
				</Container>
				<Attention></Attention>
			</Wrapper>
		</Outer>
	);
};
export default Privacypolicy;
