import styled, { css } from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;

// 以下共通cssの設定

// --------------------
// wrap要素
// --------------------
// 下層ページのページ上部の余白
export const UnderOuter = css`
	padding-top: 120px;
	@media ${v.media_md} {
		padding-top: 150px;
	}
`;
// デフォルトのアウター
export const OuterStyle = css`
	width: 100%;
	max-width: 1366px;
	margin: 0 auto 100px;
	@media ${v.media_md} {
		margin-bottom: 200px;
	}
`;
export const OuterPadding = css`
	padding: 0 10px;
`;
// --------------------
// 汎用スタイル
// --------------------
// 文章内改行
export const Break = styled.span`
	display: inline-block !important;
`;
// サブタイトルフォントスタイル
export const TitleFont = css`
	font-size: 1.8rem;
	@media ${v.media_md} {
		font-size: 2.6rem;
	}
`;
// リード文フォントスタイル
export const LeadFont = css`
	font-size: 1.6rem;
	@media ${v.media_md} {
		font-size: 2rem;
	}
`;
// 本文フォントスタイル
export const TxtFont = css`
	font-size: 1.4rem;
	@media ${v.media_md} {
		font-size: 1.8rem;
	}
`;
// 注意文フォントスタイル
export const AttentionFont = css`
	font-size: 1.2rem;
	@media ${v.media_md} {
		font-size: 1.6rem;
	}
`;

// --------------------
// タイトル
// --------------------
// タイトルスタイル
export const TitleStyle = css`
	${TitleFont}
	display: inline-block;
	position: relative;
	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 20px;
		height: 1px;
		background-color: ${v.color_txt};
	}
	&:before {
		left: -35px;
	}
	&:after {
		right: -35px;
	}
	@media ${v.media_md} {
		&:before,
		&:after {
			width: 30px;
		}
		&:before {
			left: -45px;
		}
		&:after {
			right: -45px;
		}
	}
`;

// --------------------
// ボタン
// --------------------
// BtnのWrapper
export const BtnOuterStyle = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	@media ${v.media_sm} {
		flex-direction: row;
	}
`;
// ボタン
export const BtnStyle = css`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 300px;
	padding: 10px 0;
	background-color: ${v.color_main};
	border: 2px solid ${v.color_main};
	border-radius: 9999px;
	text-transform: capitalize;
	text-decoration: none;
	@media all and (any-hover: hover) {
		&:hover {
			background-color: ${v.color_base};
		}
	}
	svg {
		margin-right: 10px;
	}
`;

// --------------------
// iframeコンポーネント
// --------------------
export const Youtube = styled.div`
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	margin: 0 auto;
	iframe {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
`;
