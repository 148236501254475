import React from 'react';
import FooterNews from './footer-news';
import FooterContact from './footer-contact';
import FooterMap from './footer-map';
import FooterCopyrights from './footer-copyrights';

const Footer = () => {
	return (
		<React.Fragment>
			<FooterNews />
			<FooterContact />
			<FooterMap />
			<FooterCopyrights />
		</React.Fragment>
	);
};
export default Footer;
