import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
import SNSBtns from '../sns-btns';
const v = styleVariables.var;
import { OuterStyle, LeadFont, OuterPadding } from '../style';

const Outer = styled.div`
	${OuterStyle}
	${OuterPadding}
`;
const Facebook = styled.div`
	position: relative;
	display: block !important;
	width: 100%;
	text-align: center;
`;
const Lead = styled.p`
	${LeadFont}
	margin: 30px 0 20px;
	text-align: center;
	@media ${v.media_md} {
		margin: 50px 0 30px;
	}
`;
const FooterNews = () => {
	return (
		<Outer id="news">
			<Facebook
				className="fb-page"
				data-href="https://www.facebook.com/owsfate/"
				data-tabs="timeline"
				data-width="400"
				data-height="500"
				data-small-header="true"
				data-adapt-container-width="true"
				data-hide-cover="false"
				data-show-facepile="false"
			>
				<blockquote
					cite="https://www.facebook.com/owsfate/"
					className="fb-xfbml-parse-ignore"
				>
					<a href="https://www.facebook.com/owsfate/">
						Fate - Original Work Shop
					</a>
				</blockquote>
			</Facebook>
			<Lead>最新情報は各種SNSからご確認ください</Lead>
			<SNSBtns />
		</Outer>
	);
};
export default FooterNews;
